.min_ChaletsByType .chalet {
    margin: 40px 0;
    padding: 20px;
    border-bottom: 1px solid #ddd;
}

.min_ChaletsByType .chalet-title {
    color: #333;
}

.min_ChaletsByType .chalet-location {
    color: #777;
}

.min_ChaletsByType .chalet-content{
    display: flex;
    gap: 50px;
    margin-top: 50px;
    margin-bottom: 50px;
}
.min_ChaletsByType .chalet-info {
    width: 60%;
}

.min_ChaletsByType .book-button {
    padding: 10px 30px!important;
    margin: 20px 0px!important;
  
}

.min_ChaletsByType .image-gallery {
    display: flex;
    gap: 50px;
}

.min_ChaletsByType .main-image {
    width: 65%;
    height: auto;
    border-radius: 20px;
}
.min_ChaletsByType .main-image img {
   height: 100%;
   width: 100%;
   object-fit: cover;
}

.min_ChaletsByType .thumbnail-images {
    display: grid;
    gap: 50px;
    width: 40%;
}

.min_ChaletsByType .thumbnail {
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.min_ChaletsByType .property-features {
    width: 36%;
    border: 1px solid #BC8733;
    padding: 20px;
    border-radius: 8px;
    background-color: transparent;
}

.min_ChaletsByType .property-features h4 {
    margin-bottom: 10px;
}

.min_ChaletsByType .property-features ul {
    list-style-type: unset;
    padding-left: 20px;
}

.min_ChaletsByType .property-features li {
    margin: 5px 0;
}
.min_ChaletsByType .bathrooms::before{
    content: "-";
    padding-right: 12px;
    margin-left: -20px;
}

.min_ChaletsByType .bathrooms{
    margin: 7px 0px;
}
@media (max-width:767px) {
    .min_ChaletsByType .chalet-content{
        flex-wrap:wrap;

    }
    .min_ChaletsByType .chalet-info{
        width: 100%;
    }
    .min_ChaletsByType .property-features{
        width: 100%;
    }
    .min_ChaletsByType .image-gallery {
        gap: 20px;
    }
    .min_ChaletsByType .thumbnail-images {
        gap: 20px;
    }
    .min_ChaletsByType .chalet-content {
        gap: 0px;
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .min_ChaletsByType .container{
        /* margin-bottom: 45px; */
    }
    .min_ChaletsByType .thumbnail {
        border-radius: 9px;
    }
    .min_ChaletsByType .main-image {
        border-radius: 9px;
    }
}